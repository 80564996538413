<script>
export default {
  props: {
    trainingItem: Object,
  },
  computed: {
    entrypoint() {
      return `${this.trainingItem.content.entrypoint}`;
    },
  },
  methods: {
    getScormData() {
      const storedData = JSON.parse(localStorage.getItem('scorm')) ?? { id: this.trainingItem.id };
      if (storedData.id !== this.trainingItem.id) {
        return { id: this.trainingItem.id };
      }
      return storedData;
    },
    onComplete() {
      this.$emit('complete');
    },
  },
  mounted() {
    // Inject Scorm API
    const scormData = this.getScormData();
    const persist = () => {
      localStorage.setItem('scorm', JSON.stringify(scormData));
    };
    const self = this;
    // Scorm 2004
    window.API_1484_11 = {
      Initialize() {
        return true;
      },
      Terminate() {
        self.onComplete();
        return true;
      },
      GetValue(element) {
        return scormData[element] ?? '';
      },
      SetValue(element, value) {
        scormData[element] = value;
        if (element === 'cmi.suspend_data') {
          persist();
        }
        console.log('set', element, value);
        return value;
      },
      Commit() {
        console.log('COMMIT');
        persist();
        return true;
      },
      GetLastError() {
        return null;
      },
      GetErrorString(errorCode) {
        return `Erreur inconnue${errorCode}`;
      },
      GetDiagnostic(errorCode) {
        return `Diagnostic inconnu${errorCode}`;
      },
    };

    // Scorm 1.2
    window.API = {
      LMSInitialize: window.API_1484_11.Initialize,
      LMSFinish: window.API_1484_11.Terminate,
      LMSGetValue: window.API_1484_11.GetValue,
      LMSSetValue: window.API_1484_11.SetValue,
      LMSCommit: window.API_1484_11.Commit,
      LMSGetLastError: window.API_1484_11.GetLastError,
      LMSGetErrorString: window.API_1484_11.GetErrorString,
      LMSGetDiagnostic: window.API_1484_11.GetDiagnostic,
    };
  },
};
</script>

<template>
  <div class="ctitcomp">
    <iframe :src="entrypoint" class="iframe"></iframe>
  </div>
</template>

<style scoped>
.iframe {
  width: 100%;
  height: calc(100vh - 150px);
}
</style>
